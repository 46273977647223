import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EnvService } from "../core/env.service";
import { firstValueFrom } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MediaService {
  url = "unset";
  token =
    "bf1f05d97ff27d471a91215990f625ff73e1670688df4b7110e97b072d83f892de5e0bdb2f7b38070aaab1aced161c4509153f486fcb08dfa4dbd223e8acd5d285356c673c8e39339f2e17fb64c50013973ed7d68bbee492e15c6ad6b81537a161403b5f633206e20f37e106c9b5886fa21c0d07510a7496db229196d20d84d9";

  constructor(private http: HttpClient, private env: EnvService) {
    this.url = this.env.strapiServer + "/strapi";
    console.log(this.url);
  }

  getMediaFiles() {
    return this.http.get<any>(
      this.url + "/api/upload/files/",
      this.getHeader()
    );
  }

  getMediaFileById(id: number) {
    return this.http.get<any>(
      this.url + "/api/upload/files/" + id,
      this.getHeader()
    );
  }

  updateMediaFileById(id: number, data) {
    return this.http.post<any>(this.url + "/api/upload?id=" + id, data, {
      headers: new HttpHeaders({ Authorization: `bearer ${this.token}` }),
    });
  }

  deleteMediaFileById(id: number) {
    return this.http.delete<any>(this.url + "/api/upload/files/" + id, {
      headers: new HttpHeaders({ Authorization: `bearer ${this.token}` }),
    });
  }

  getImageUrl(url) {
    return this.url + url;
  }

  getImageUrlText(url) {
    const headers = new HttpHeaders();
    return this.http.get(url, { headers, responseType: "text" });
  }

  getHeader() {
    return {
      headers: new HttpHeaders({
        Authorization: `bearer ${this.token}`,
        "Content-Type": "application/json",
      }),
    };
  }

  uploadMediaFile(data) {
    return this.http.post<any>(this.url + "/api/upload", data, {
      headers: new HttpHeaders({ Authorization: `bearer ${this.token}` }),
    });
  }
}
