<div class="modal-header">
  <h6 class="modal-title" id="modal-basic-title">
    {{ "Add Medias" | ioxTranslate }}
  </h6>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="modal.dismiss('Cross click')"
  ></button>
</div>
<div class="modal-body">
  <div class="card-body">
    <div class="row justify-content-end mb-4 pb-2 border-bottom">
      <div class="col-lg-2 col-md-3 col-sm-6">
        <div class="upload-btn-wrapper">
          <button class="btn btn-primary">
            <i class="fa fa-upload pe-2" aria-hidden="true"></i>Upload a file
          </button>
          <input type="file" (change)="uploadMedia($event.target.files)" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-6" *ngFor="let images of imagesUrls">
        <div class="img-card" (click)="toggle(images.id)">
          <div class="img-div">
            <img [src]="images.url" alt="customer image" />
          </div>
          <div class="btn-div">
            <div class="add">
              <input
                type="checkbox"
                class="form-check-input"
                [checked]="selectedIds.includes(images.id)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" (click)="saveMedia()" class="btn btn-primary">
    Save
  </button>
</div>
