import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MediaService } from "../../services/strapi/media.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-add-media-popup',
  templateUrl: './add-media-popup.component.html',
  styleUrls: ['./add-media-popup.component.scss']
})
export class AddMediaPopupComponent {
  @Input() selectedIds = [];
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  imagesUrls: any = [];
  title = "angular";
  selectedImage: any;
  fileSvg: any;
  constructor(
    private mediaService: MediaService,
    public modal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.getMedia();
  }

  getMedia() {
    this.mediaService.getMediaFiles().subscribe((res) => {
      res.forEach((file: any) => {
        // if (file.ext === ".svg")
          this.imagesUrls.push({
            ...file,
            url: this.mediaService.getImageUrl(file.url),
          });
      });
    });
  }

  toggle(id) {
    if (this.selectedIds.includes(id)) {
      this.selectedIds = this.selectedIds.filter((item) => item !== id);
    } else {
      this.selectedIds.push(id);
    }
  }

  saveMedia() {
    this.onSave.emit(
      this.imagesUrls.filter((img) => this.selectedIds.includes(img.id))
    );
  }

  uploadMedia(files: FileList) {
    const file = files[0];
    // file.type === "image/svg+xml"
    const formData = new FormData();
    formData.append("files", file);
    formData.append("fileInfo", JSON.stringify({ name: file.name }));
    this.mediaService.uploadMediaFile(formData).subscribe((res) => {
      const file = res[0];
      this.imagesUrls.push({
        ...file,
        url: this.mediaService.getImageUrl(file.url),
      });
      this.selectedIds.push(file.id);
    });
  }
}
